import React from 'react';
import PROJECTS from './PROJECT_CONTENT';
import Project from './Project';
import style from './portfolio.module.less';

const Portfolio = () => (
  <div className={style.portfolio}>
    {/* <div className={style.wrapper}> */}
    <h2 className={style.sectionTitle} style={{ marginTop: '10px' }}><span>Contract Projects</span></h2>
    {/* </div> */}
    {PROJECTS.CLIENT.map((project) => <Project project={project} key={project.title} />)}
    <h2 className={style.sectionTitle}><span>Personal Projects</span></h2>
    {PROJECTS.PERSONAL.map((project) => <Project project={project} key={project.title} />)}
    <h2 className={style.sectionTitle}><span>Employment</span></h2>
    {PROJECTS.EMPLOYMENT.map((project) => <Project project={project} key={project.title} />)}
  </div>
);

export default Portfolio;
