import React from 'react';
import {
  Layout,
  // Row,
  // Col,
} from 'antd';
import Header from '../../components/PageLayout/Header';
import SidebarWrapper from '../../components/PageLayout/Sidebar';
import SEO from '../../components/Seo';
import Portfolio from '../../components/Portfolio';

const PortfolioPage = () => (
  <Layout className="outerPadding">
    <Layout className="container">
      <SEO
        title="Portfolio"
        description="Hello folks, Stuart here. Feel free to browse my portfolio!"
        path="/portfolio"
        keywords={['Stuart', 'Jehn', 'FullStack developer', 'Frontend developer', 'Javascript', 'ReactJS', 'NodeJS', 'Gatsby', 'technology']}
      />
      <Header />
      <SidebarWrapper>
        <div className="marginTopTitle">
          <h1 className="titleSeparate">Portfolio</h1>
        </div>
        {/* <Row gutter={[40, 20]}>
        </Row> */}
        <Portfolio />
      </SidebarWrapper>
    </Layout>
  </Layout>
);

export default PortfolioPage;
