const PROJECTS = {
  CLIENT: [
    {
      title: "Orchard Press",
      imgSrc: "../../../orchard-press.mp4",
      content:
        "A web application for a new independent publishing company. The client wanted a simple modern style with the ability to browse authors and purchase books through Stripe. The client also wanted an optimized SEO, which made GatsbyJS a great choice. The project was completed successfully, and many orders have already been placed!",
      href: "http://orchardpresspublishing.com/",
      startDate: "2020",
      endDate: "2020",
    },
  ],
  PERSONAL: [
    {
      title: "Street Byter",
      imgSrc: "../../../streetbyte.mp4",
      content:
        "A web application which allows users to race each other in real-time to solve coding problems. Additionally, there are “battle” features which enable players to freeze their opponents screen, swap code, and more fun! User code is run on an AWS EC2 instance where Docker containers are instantiated to safely run the code and return the result to the client.",
      href: null,
      startDate: "2019",
      endDate: "Present",
    },
    {
      title: "Jamie Chat",
      imgSrc: "../../../jamie.mp4",
      content:
        "A chat application designed to fetch YouTube videos, tweets, Wikipedia articles, and gifs related to the conversation in real-time. Utilized Google's Natural Language API to decompose chat messages into the sentiment and salience of the subject matter. Then, based on a custom algorithm, a specific API request is made to a media platform and the result is rendered to the users.",
      href: null,
      startDate: "2018",
      endDate: "Present",
    },
  ],
  EMPLOYMENT: [
    // {
    //   title: "Winit",
    //   imgSrc: "../../../winit.mp4",
    //   content:
    //     "With over a year as a developer at Winit, my team was tasked with migrating the main Angular app to React and building out features such as: traffic and parking ticket lookup with checkout utilizing the city’s database, SMS and email services for ticket notifications, and creating a new Node.js API. Feel free to check out our work and fight a ticket if you have one!",
    //   href: "https://www.appwinit.com/",
    //   startDate: "2019",
    //   endDate: "2020",
    // },
  ],
}

export default PROJECTS
