import React from 'react';
import { Row, Col } from 'antd';

// import { Grid, Col, Row } from 'react-styled-flexboxgrid';
// import { ThemeProvider } from 'styled-components';
import style from './project.module.less';


// const theme = {
//   flexboxgrid: {
//     // Defaults
//     gridSize: 12, // columns
//     gutterWidth: 1, // rem
//     outerMargin: 0, // rem
//     mediaQuery: 'only screen',
//     container: {
//       sm: 30, // rem
//       md: 40, // rem
//       lg: 50, // rem
//     },
//     breakpoints: {
//       xs: 0, // em
//       sm: 33, // em
//       md: 43, // em
//       lg: 53, // em
//     },
//   },
// };

// const COLUMNS = {
//   content: {
//     lg: 6,
//     md: 6,
//     sm: 12,
//     xs: 12,
//   },
//   video: {
//     lg: 6,
//     md: 6,
//     sm: 12,
//     xs: 12,
//   },
// };


const Project = ({ project }) => (
  <div className={style.project}>
    <Row gutter={[20, 20]}>
      <Col sm={24} md={24} lg={12}>
        <h3 className={style.projectTitle}>
          {project.title}
          {' '}
          {`(${project.startDate}-${project.endDate})`}
        </h3>
        {/* <h3 className={style.date}>
          {`(${project.startDate}-${project.endDate})`}
        </h3> */}
        <div className={style.anchorWrap}>
          {
            project.href ? (
              <a className={style.anchor} href={project.href} target="_blank" rel="noopener noreferrer">
                {project.href}
                {/* <button type="button" className={style.button}>View Site</button> */}
              </a>
            ) : <span className={style.noHref}>Deployment Coming Soon!</span>
          }
        </div>
        <div className={style.content}>
          {project.content}
        </div>
      </Col>
      <Col sm={24} md={24} lg={12}>
        <video autoPlay loop muted playinline="true" className={style.video}>
          <source src={project.imgSrc} type="video/mp4" className="widthFull contactImgBorder" />
        </video>
      </Col>
    </Row>
  </div>
);

export default Project;
